import React from "react"
import 'bootstrap/dist/css/bootstrap.css'
import SignUp from "../components/auth/signup-component"

const SignUpPage = () => (
  
 <SignUp></SignUp>
  
)

export default SignUpPage
