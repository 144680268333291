import React from "react";
import firebase from './fire-base.js';
import auth from 'firebase/app'
import "firebase/auth"
import { Link } from "gatsby";
import Logo from "../../images/orange.png";
import {IoMdArrowRoundBack} from "react-icons/io"
import { navigate } from "gatsby"
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, TextField } from "@material-ui/core";
import { FaSadCry } from "react-icons/fa";

require('dotenv').config();

const useStyles = ()=>({
  wrapper: {
      position: 'relative',
      marginTop: 20,
    },
    buttonProgress: {
      color: 'green',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    submitButton:{
      backgroundColor:'#e7523a',
      color:'white',
      fontWeight: 'bold',
      width:'100%',
      '&:hover':{
        backgroundColor:'#e7523a!important'
      }
    }
});

class SignUpComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      fullName: "",
      error: "",
      isSignUp:false,
    };
    this.displayError = React.createRef();
  }

  handleSignUpInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    const { email, password, fullName } = this.state;
    this.setState({error: ""})
    this.displayError.current.hidden = true

    if(email !== "" && password !== "" && fullName !== "") {
      this.setState({isSignUp: true})
       var newUser;
        firebase
         .auth()
         .createUserWithEmailAndPassword(this.state.email, this.state.password)
         .then((user) => {
           if(user){
             newUser = user;
             user.user.updateProfile({
             }).then((s)=>
                {
                  this.addUser(newUser.user);
                  this.displayError.current.hidden = true;
                }
             )
           }
         })
         .catch((error) => {
             if(error.code == "auth/weak-password"){
               this.setState({error: "Password is too weak"});
               this.displayError.current.hidden = false;
             }
             if(error.code == "auth/email-already-in-use") {
               this.setState({error: "Email is already in use"});
              this.displayError.current.hidden = false;
             }
             setTimeout(()=>{ this.displayError.current.hidden = true }, 3000)
             this.setState({isSignUp: false})
         });
    }
    else {
      this.setState({error: "All fields required"})
      this.displayError.current.hidden = false;
      setTimeout(()=>{ this.displayError.current.hidden = true }, 3000)
    }
  };

  async addUser(user) {
     await firebase.analytics().logEvent('New SignUp', { name: 'Account Created'})
     await firebase.firestore().collection("users").doc(user.uid).set(
     {  
       uid: user.uid,
        name: this.state.fullName,
        email: this.state.email,
        memberSince: new Date(),
        category: "",
        expireDate: (()=>{ 
            let expireDate = new Date();
            expireDate.setDate((new Date()).getDate()+14);
            return expireDate;
          })(),
        referralCode: localStorage.getItem('referralCode'),

     },{merge: true}).then(()=>{ navigate("/completeprofile/") })
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  async addName(user) {
    await firebase.analytics().logEvent('New Google SignUp', { name: 'Account Created'})
    await firebase.firestore().collection("users").doc(user.uid).set(
    {
      name: user.displayName,
      referralCode: localStorage.getItem('referralCode'),
    },{merge: true}).then(()=>{ navigate("/completeprofile/") })
  }

   handleGoogleSubmit = (event) => {
    event.preventDefault();
    // Using a popup.
    var provider = new auth.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');

    firebase.auth().signInWithPopup(provider).then(result => {
      this.addName(result.user)
    })
    .catch((error) => {});

  }

  submit = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    const {classes} = this.props
    return (
    <>
      <div className = "auth-header"><Link to="/"><IoMdArrowRoundBack className="back-arrow"/></Link><img src = {Logo} className = "auth-logo"/></div>
        <div className = "auth-form-wrapper">
          <form className = "auth-form col-sm-6 col-md-6 col-lg-4" onSubmit={this.submit} >
            <h4 className = "text-center">Sign Up</h4>
            <p className = "text-center">Already have an account? <Link to="/signin" className = "auth-link">Sign in</Link></p>
            <div className="text-center">
            <button type="button" className = "auth-btn-google col-md-10 text-center" onClick={this.handleGoogleSubmit}>Continue with Google</button>
            </div>
            <div className="form-group text-center px-2">
                <TextField
                    type='text'
                    className='w-100 mt-3'
                    placeholder='Full Name'
                    style={{ fontSize: '24px' }}
                    value={this.state.fullName}
                    onChange={this.handleSignUpInputChange}
                    name="fullName"
                    disabled={this.state.isSignUp}
                />
                <TextField
                    type='email'
                    className='w-100 mt-3'
                    placeholder='Email'
                    value={this.state.email}
                    onChange={this.handleSignUpInputChange}
                    name="email"
                    disabled={this.state.isSignUp}
                />
                <TextField
                    type='password'
                    className='w-100 mt-3'
                    placeholder='Password (8 char, minimum)'
                    value={this.state.password}
                    onChange={this.handleSignUpInputChange}
                    name="password"
                    disabled={this.state.isSignUp}
                />
            </div>
            <div className="text-center">
              <div className={classes.wrapper}>
                <Button 
                  type="submit" 
                  className = {classes.submitButton}
                  onClick={this.handleSubmit}
                  disabled={this.state.isSignUp}
                >
                  Sign Up
                </Button>
                {this.state.isSignUp && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </div>
            <p hidden id="error" style={{textAlign: "center", color: "red"}} ref={this.displayError}>{this.state.error}</p>
          </form>
        </div>
     </>
    )
  }
}

export default withStyles(useStyles)(SignUpComponent);
